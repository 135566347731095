import { Auth } from 'aws-amplify';
// import { cache } from '../../environment';
import AccountsStorage from '../components/amplify_storage';
import localStorage from '../components/localStorage';

import { DateTime } from 'luxon';
import { TvRounded } from '@material-ui/icons';
import isURL from 'validator/lib/isURL';


const CryptoJS = require('crypto-js');

const OFFLINE_STORE_KEY = "@OfflineStore:";

export async function login(email, password) {
  const user = await Auth.signIn(email, password);
  return user;
}

export const aiOrApp = (function () {
  if (process.env.REACT_APP_env == "dev") {
    const {port} = new URL(window.location.href);
    return port == 3001 ? 'ai' : 'app';
    // return "ai"; // "ai"
  }

  const {host}  = window.location;
  return host.slice(host.lastIndexOf('.') + 1);
})();


export async function getAuthState() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user;
  }
  catch (err) {
    console.warn('getAuthState error: ', err.message || err);
  }
}

export async function forgotPasswordSubmit(username, code, new_password) {
  await Auth.forgotPasswordSubmit(username, code, new_password);
  const user = await login(username, new_password);
  return user;
}

export async function forgotPassword(username) {
  const user = await Auth.forgotPassword(username);
  return user;
}

export async function signup({ email, password, username, property }) {
  const params = {
    username: email,
    password,
    attributes: {
      name: username,
      "custom:property": property
    }
  }
  const user = await Auth.signUp(params);
  return user;
}

export const clearStorageButItem = async(item) => {
  try {
    await localStorage.clear()
    const value = window.localStorage.getItem(item);
    window.localStorage.clear();

    if (value) {
      window.localStorage.setItem(item, value);
    }
  }
  catch(e) {
    console.error(e);
  }

  // return true;
}

async function logoutFromMyhyly(user) {
  try {
    console.log("logging out from myhyly...");
    const user = await Auth.currentAuthenticatedUser();
    // if (user && process.env.NODE_ENV !== "development") {
    await fetch("https://" + process.env.REACT_APP_MyhylyHost + "/from_cognito?logout=1" + "&token=" + user.signInUserSession.idToken.jwtToken);
    // }

  } catch (error) {
    console.log('logging out from myhyly - error: ', error);
  }
}


export const constructCognitoLogoutPath = user => {
  return encodeURIComponent(
    [
      'https://',
      process.env.REACT_APP_COGNITO_AUTH_DOMAIN,
      "/logout?",
      "client_id=",
      user.pool.clientId,
      '&logout_uri=',
      'https://',
      process.env.REACT_APP_host
    ].join('')
  );
}


export async function logout() {
  try {
    // await logoutFromMyhyly();
    // console.log("-- logged out from myhyly --")
    await Auth.signOut();
    // await
    console.log("-- Auth signed out --")
    await clearStorageButItem('redirect-app-after-auth');

    // make sre cookies are also cleared out
    console.log("-- log out completed successfully --");
  }
  catch (err) {
    console.error('logout error: ', err);
  }
}

export async function userExists(email, redirectToCompleteRegister) {
  try {
    await Auth.signIn(email, "123");
  } catch (error) {
    switch (error.code) {
      case 'UserNotFoundException':
        return false;
      case 'NotAuthorizedException':
        return true;
      case 'PasswordResetRequiredException':
        return true;
      case 'UserNotConfirmedException':
        return 'UserNotConfirmedException';
      default:
        return false;
    }
  }
}

export async function confirmSignup(email, code) {
  const user = await Auth.confirmSignUp(email, code);
  return user;
}

export async function resendSignUp(username) {
  const user = await Auth.resendSignUp(username);
  return user;
}

export async function resetPassword(email, code, new_password) {
  await Auth.forgotPasswordSubmit(email, code, new_password);
}

export async function changePassword(oldPassword, newPassword) {
  const user = await Auth.currentAuthenticatedUser()
  const data = await Auth.changePassword(user, oldPassword, newPassword);
  return data;
}

// export function getInvite(params) {
//   try {
//     params = params.token || params;

//     if (!(/^token=.*/.test(params))) {
//       throw new Error('no pending');
//     }
//     const token = decodeURIComponent(params.slice(params.indexOf('=') + 1));
//     const decoded = CryptoJS.AES.decrypt(token, process.env.REACT_APP_SECRET);
//     const { group_id, invite_id } = JSON.parse(decoded.toString(CryptoJS.enc.Utf8));
//     if (!group_id || !invite_id) { throw new Error('no pending'); }
//     return { group_id, invite_id };
//   }
//   catch (err) {
//     console.warn(err.message);
//     return false;
//   }
// }

export function createMyhylyURL(host, user, suffix = "") {
  return [
    "https://",
    host,
    "/from_cognito?",
    "token=",
    user.signInUserSession.idToken.jwtToken,
    suffix
  ].join('')
}

export function gotoMyhyly(user, hostName = 'myhyly', suffix) {
  let host;
  switch (hostName) {
    case 'myhyly-probe':
      host = process.env.REACT_APP_MyhylyProbHost;
      break;
    case 'myhyly':
    default:
      host = process.env.REACT_APP_MyhylyHost
  }

  window.location = createMyhylyURL(host, user, suffix);
}

export function getStringValue(str, key) {
  return decodeURIComponent(str.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

export function getQueryStringValue(key) {
  return getStringValue(window.location.search, key);//decodeURIComponent(.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

function handleSuffix(suffix = []) {
  if (!suffix.length) { return ''; }
  return "?" + suffix.join("&");
}

function pathFromNameByEnv(name, aiOrApp) {
  const env = process.env.REACT_APP_env;
  const getDevPort = ['hysurvey', , 'hyiq'];

  if (isURL(name)) { return name; }
  
  switch (env) {
    case 'dev':
      const ind = getDevPort.indexOf(name);
      return name == "accounts"
      ? `http://localhost:300${aiOrApp == "ai" ? "1" : "2"}`
      : "http://localhost:300" + ( ind > -1 ? ind : 0 );
    case 'qa': return `https://${name}-qa.hyly.${aiOrApp}`;
    case 'stg': return `https://${name}-stg.hyly.${aiOrApp}`;
    case 'production': return `https://${name}.hyly.${aiOrApp}`;
  }
}

function pathFromNameWithSuffix(name, aiOrApp, suffix = []) {
  const domain = pathFromNameByEnv(name, aiOrApp);
  return domain + handleSuffix(suffix);
}



export function constructPathFromName(name, aiOrApp) {

  // const from = getQueryStringValue("from");
  switch (name) {
    case 'myhyly':
    case 'myhyly-probe': return name;
    case 'set-password':
    case 'verify': return '/';
    case 'journey-ai':
    case 'journey':
      return pathFromNameByEnv('journey', 'ai');
    case 'pai-ai':
    case 'pai':
      return pathFromNameByEnv('pai', 'ai');
    default:
      return pathFromNameByEnv(name, 'app');
  }
}

export function handle_referrer(ref) {
  return ref.replace(/(^https?:\/\/[a-zA-Z\.]+(:[0-9]+)?)(\/{1}[0-9a-zA-Z-_@=\&\.\+\/]+)/, (m, m1, m2) => m1);
}

export const handleGotoPath = (aiOrApp) => {

  const { goto, alias } = findGotoPath(aiOrApp);

  if (goto == "/") { return {} }

  if (/hy.ly|hyly/gi.test(alias)) {
    return {
      goto,
      alias: /probe/gi.test(alias) ? "myhyly-probe" : "myhyly"
    }
  }

  else if (/set-password|login|verify/gi.test(alias)){
    return {
      goto,
      alias: goto.match(/set-password|login|verify/gi)[0]
    }
  }

  else {
    return { goto, alias }
  }
}

const replaceAppAI = str => {
  // app case
  if (!/\.hyly\.app\/\?goto\=/.test(str)) {
    return str.replace(/(https:\/\/)([a-z-]+)(\.hyly\.app)/i, (m, m1, m2) => m2);
  }
  // ai case
  return str.substr(str.indexOf('?goto=') + 6);
}

export function findGotoPath(aiOrApp) {
  let goto, alias, noRedirect;

  const cacheValue = window.localStorage.getItem('redirect-app-after-auth'),
        queryValue = getQueryStringValue("goto");

  if (cacheValue && cacheValue !== 'accounts') {
    alias = cacheValue;
    goto = constructPathFromName(cacheValue, aiOrApp);
    console.log(`${new Date().toTimeString()}: found goto in cache. redirecting to ${goto}`)
  }

  else if (queryValue) {
    alias = queryValue;
    goto = constructPathFromName(queryValue, aiOrApp);
    console.log(`${new Date().toTimeString()}: no goto in cache. found goto in query. redirecting to ${goto}`);
  }

  else {
    noRedirect = true
    console.log(`${new Date().toTimeString()}: no goto in query or in cache. redirecting to accounts`);
    alias = "accounts";
    goto = "/";
  }

  // if (!no_redirect) {
  //   window.localStorage.setItem('redirect-app-after-auth', goto);
  // }

  return {goto, alias};
}

// accounts.app?goto=hymail-ai&from=accounts =>
// accounts.app/login?goto=myhyly&from=myhyly (cache = hymail-ai) =>
// login
// myhyly/from_cognito?goto=hymail-ai =>
// accounts.app?goto=hymail-ai&from=myhyly =>
// accounts.ai?goto=hymail =>
// hymail.ai

export const redirectAfterAuth = async (gotoFullPath, gotoAlias, gotoDomain = process.env.REACT_APP_ACCOUNTS_LINK) => {

  console.log("removing item from local storage...");
  window.localStorage.removeItem("redirect-app-after-auth");
  const user = await getAuthState();

  if (user && ['myhyly', 'myhyly-probe'].includes(gotoAlias)) {
    return gotoMyhyly(user, gotoAlias);
  }
  else if (user) {
    // the goto is mendatory on myhyly for now
    return gotoMyhyly(user, 'myhyly', `&domain=${gotoFullPath.slice(8)}&goto=${gotoAlias}`);
  }
  else {
    return window.location.href = gotoFullPath;
  }
}


export function executeOnce() {

  return new Promise(async (res, rej) => {
    const expiration = new Date(DateTime.local().setZone("utc").plus({ days: 10 }).toString());

    let argc = arguments.length,
      bImplGlob = typeof arguments[argc - 1] === "string";

    if (bImplGlob) { argc++; }
    if (argc < 3) {
      return rej(new TypeError("executeOnce - not enough arguments"));
    }
    let fExec = arguments[0],
      sKey = arguments[argc - 2];
    if (typeof fExec !== "function") {
      return rej(new TypeError("executeOnce - first argument must be a function"));
    }
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
      return rej(new TypeError("executeOnce - invalid identifier"));
    }
    if (decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) === "1") {
      return res(false);
    }
    await fExec.apply(argc > 3 ? arguments[1] : null, argc > 4 ? Array.prototype.slice.call(arguments, 2, argc - 2) : []);
    document.cookie = encodeURIComponent(sKey) + "=1; expires=" + expiration.toUTCString() + (bImplGlob || !arguments[argc - 1] ? ";SameSite=Strict;path=/" : "");
    return res(true);
  })
}
